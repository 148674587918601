import Table from 'components/Table'
import { createHeaders } from 'components/Table'
import { dateFormat } from 'libs/utils'
import { currency } from 'libs/utils'
import React, { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { Button } from 'reactstrap'
import { ordenService } from 'services/ordenService'

import ModalDetalleOrden from './components/ModalDetalleOrden'
import PageContainer from './components/PageContainer'
import { calcularTotalOrden } from './utils'

const Ordenes = () => {
  const getOrdenes = async () => {
    const { data } = await ordenService.getAll()

    return data
  }

  const [detalles, setDetalles] = useState(null)
  const [show, setShow] = useState(false)

  const { data, isLoading, refetch } = useQuery('ordenes', getOrdenes, {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })

  const onClickDetalle = (orden) => {
    setDetalles({ datos: orden, productos: orden.detalle })
    setShow(true)
  }

  const columns = useMemo(
    () => [
      createHeaders('#Orden', 'conekta_order_id'),
      createHeaders('Cliente', 'nombre'),
      createHeaders('Domicilio', 'direccion', (props, row) => {
        return props.value
          ? props.value
          : `Recolección en sucursal: ${row.tienda_id}`
      }),
      createHeaders('Total', 'total', (_, row) => {
        const { total } = calcularTotalOrden(row)

        return currency(total)
      }),
      createHeaders('Creado', 'created_at', (_) => {
        return dateFormat(_.value)
      }),
      createHeaders('Tipo de Pago', 'metodo_pago'),
      createHeaders('Estatus', 'estatus'),
      createHeaders('Detalle', 'detalle', (_, row) => {
        return (
          <Button color="info" size="sm" onClick={() => onClickDetalle(row)}>
            Detalle
          </Button>
        )
      }),
    ],
    []
  )

  return (
    <PageContainer
      title="Ordenes"
      isLoading={isLoading}
      refetch={refetch}
      loadingMessage="Cargando ordenes"
    >
      <Table columns={columns} data={data} />
      {detalles && (
        <ModalDetalleOrden
          detalles={detalles}
          show={show}
          onClose={() => setShow(false)}
        />
      )}
    </PageContainer>
  )
}

export default Ordenes
