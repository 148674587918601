import axios from 'axios'

const http = () => {
  const request = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${localStorage.bees_token}`,
    },
  })
  const auth = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      // 'Authorization' : `Bearer ${localStorage.bees_token}`
    },
  })
  const upload = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${localStorage.bees_token}`,
    },
  })

  return {
    auth,
    request,
    upload,
  }
}

export const HTTP = axios.create({
  baseURL: process.env.REACT_APP_API_URL + '/api',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    Authorization: `Bearer ${localStorage.bees_token}`,
  },
})

export const setAuthorizationToken = (token) => {
  HTTP.defaults.headers = {
    ...HTTP.defaults.headers,
    Authorization: `Bearer ${token}`,
  }
}

export default http
