import { notificacion_nueva_orden } from 'constants/sounds'
import { Store } from 'layouts/Context'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { ordenService } from 'services/ordenService'

const LogisticaHook = () => {
  const { createNotification } = useContext(Store)
  const [numeroOrdenes, setNumeroOrdenes] = useState(0)
  const [primeraCarga, setPrimeraCarga] = useState(true)
  const [rowNuevo, setNuevo] = useState(null)
  const [rowAtendido, setAtendido] = useState(null)
  const [detalles, setDetalles] = useState({
    datos: {
      id: 0,
      numero_orden: '',
      nombre: '',
      correo: '',
      direccion: '',
      coordenadas: '',
      ciudad: '',
      telefono: '',
      monto: '',
      recoger_tienda: '',
      creado: '',
      tipo_pago: '',
      estatus: '',
      oxxo_estatus: '',
      texto_tipo_pago: '',
    },
    productos: [],
  })
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (detalles.datos.id > 0) setShow(true)
  }, [detalles])

  const getOrdenes = async () => {
    try {
      const { data } = await ordenService.getLogistica()

      if (!primeraCarga && data.length > numeroOrdenes) {
        const soundNotification = new Audio(notificacion_nueva_orden)

        soundNotification.play()

        createNotification('info', 'Nuevas ordenes por atender')
      }

      const ordenes = data.reduce(
        (coleccion, item) => {
          item.creado = moment(item.created_at).format('DD/MM/YYYY')
          if (item.metodo_pago === 'Oxxo' && !item.pagado) {
            item.texto_tipo_pago = (
              <span className="text-uppercase text-danger">
                {item.metodo_pago} - Por pagar
              </span>
            )
          } else {
            item.texto_tipo_pago = item.metodo_pago.toUpperCase()
          }
          item.texto_estatus = item.pagado ? 'Pagado' : 'Por pagar'
          item.texto_direccion = item.direccion
            ? item.direccion
            : `Recolección en ${item.tienda_id} `

          if (item.estatus === 'Nueva') {
            coleccion.nuevas.push(item)
          } else {
            coleccion.atendidas.push(item)
          }

          return coleccion
        },
        { nuevas: [], atendidas: [] }
      )

      setNumeroOrdenes(data.length)
      setPrimeraCarga(false)
      return ordenes
    } catch (error) {
      console.log(error)
      throw Error('Error al consultar las ordenes')
    }
  }
  const seleccion = (row) => {
    if (row.estatus === 'Nueva') {
      if (row.metodo_pago === 'Oxxo' && !row.pagado) {
        createNotification(
          'warning',
          'Esta orden aun no se ha recibido pago de oxxo',
          'Atención'
        )
      }
      setNuevo(row)
    } else {
      setAtendido(row)
    }
  }
  const HandleAction = async (estatus, row) => {
    try {
      const { data } = await ordenService.update(row.id, estatus)
      createNotification('info', 'Estatus de la orden actualizada')
      await getOrdenes()

      return data
    } catch (err) {
      console.log(err)
      createNotification('error', 'Error al actualizar el estatus')
      return null
    }
  }
  const abrirDetalles = async (row) => {
    setDetalles({ datos: row, productos: row.detalle })
  }
  return {
    rowNuevo,
    rowAtendido,
    detalles,
    show,
    HandleAction,
    abrirDetalles,
    setShow,
    getOrdenes,
    seleccion,
  }
}

export default LogisticaHook
