import ModalComponent from 'components/Modal/ModalComponent'
import Rate from 'components/Rate'
import { currency } from 'libs/utils'
import React from 'react'
import { Card, Col, Row } from 'reactstrap'

import { calcularTotalOrden } from '../utils'

const ModalDetalleOrden = ({ detalles, show, onClose }) => {
  const { datos, productos } = detalles
  const { total, total_original } = calcularTotalOrden(datos)
  const { coordenadas } = datos
  return (
    <ModalComponent
      title={'Detalles de la Orden #' + datos.conekta_order_id}
      isOpen={show}
      toggle={onClose}
    >
      <div>
        <Card body>
          {datos.productos_calificacion && <RateOrden datos={datos} />}
          <h3>Información de la orden</h3>
          <div className="row">
            <div className="col-md-6 mb-3">
              <label htmlFor="nombre">Cliente:</label>
              <div>
                <label>{datos.nombre}</label>
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="apellido">Teléfono</label>
              <div>
                <p>{datos.telefono}</p>
              </div>
            </div>

            <div className="col-md-6 mb-3">
              <label>Subtotal</label>
              <p className="font-weight-bold">{currency(datos.monto)}</p>
            </div>
            <div className="col-md-6 mb-3">
              <label>Costo envío</label>
              <p className="font-weight-bold">
                {datos.envio_gratis ? (
                  <>
                    <span className="text-success mr-2">$0.00</span>
                    <s className="mr-1">{currency(datos.costo_envio)}</s>
                    <small>(Costo original)</small>
                  </>
                ) : (
                  currency(datos.costo_envio)
                )}
              </p>
            </div>

            <div className="col-md-6 mb-3">
              <label>Descuento</label>
              <p
                className={`font-weight-bold ${
                  datos.descuento > 0 && 'text-success'
                } `}
              >
                {currency(datos.descuento)}
              </p>
            </div>

            <div className="col-md-6 mb-3">
              <label>Total</label>
              <p className="font-weight-bold">
                {total !== total_original ? (
                  <>
                    <span className="text-success mr-2">{currency(total)}</span>
                    <s className="mr-1">{currency(total_original)}</s>
                    <small>(Precio original)</small>
                  </>
                ) : (
                  currency(total)
                )}
              </p>
            </div>

            {datos.cupon && (
              <div className="col-md-12">
                <label>Cupon aplicado:</label>
                <p className="font-weight-bold">
                  {datos.cupon.nombre} -{' '}
                  <span>
                    {datos.cupon.es_porcentaje
                      ? `${datos.cupon.descuento}%`
                      : currency(datos.cupon.descuento)}{' '}
                  </span>
                </p>
              </div>
            )}

            <div className="col-md-12 mb-3">
              <label htmlFor="apellido">Dirección:</label>
              {datos.direccion !== '' ? (
                <>
                  <p>{datos.direccion}</p>

                  <span>
                    <a
                      className="btn btn-default"
                      href={`https://www.google.com/maps/?q=${coordenadas.lat},${coordenadas.lng}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fas fa-map-marker-alt"></i> Ver ubicación
                    </a>
                  </span>
                </>
              ) : (
                <p>
                  El Pedido sera recogido en sucursal:{' '}
                  <span className="font-weight-bold text-uppercase">
                    {datos.tienda_id}
                  </span>
                </p>
              )}
            </div>
          </div>
          <hr className="mb-4" />
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h3>Lista de Productos</h3>
          </div>
          {/* Detalles de la orden {detalles.id} lista de los productos aqui */}
          <div className="col-md-12 order-md-2 mb-4">
            <ul className="list-group list-group-flush mb-3">
              {productos.map((item) => (
                <ProductoDetalles key={item.producto_id} item={item} />
              ))}
            </ul>
          </div>
        </Card>
      </div>
    </ModalComponent>
  )
}

const RateOrden = ({ datos }) => {
  return (
    <>
      <h3>Calificaciones de la orden</h3>
      <Row>
        <Col>
          <span>Productos</span>
          <Rate disabled showText defaultValue={datos.productos_calificacion} />
        </Col>
        <Col>
          <span>Servicio</span>
          <Rate disabled showText defaultValue={datos.servicio_calificacion} />
        </Col>
        <Col>
          <span>Atención</span>
          <Rate disabled showText defaultValue={datos.atencion_calificacion} />
        </Col>
      </Row>
      <hr />
    </>
  )
}

const ProductoDetalles = ({ item }) => {
  const { precio_unitario, cantidad, producto, rate, comentario } = item
  const { descripcion, url } = producto
  const total = parseFloat(precio_unitario) * parseFloat(cantidad)

  return (
    <li className="list-group-item">
      <div className="media">
        <img
          src={url}
          className="img-fluid z-depth-1 mr-3"
          style={{ width: '30%' }}
          alt={descripcion}
        />
        <div className="media-body">
          <h5 className="mt-0">{descripcion}</h5>
          <p>Precio unitario: {currency(precio_unitario)}</p>
          <p>Cantidad: {cantidad}</p>
          <p>
            Total: <span className="font-weight-bold">{currency(total)}</span>
          </p>
          {rate && (
            <div>
              <p>
                Calificación:
                <i className="fas fa-star text-yellow ml-2"></i>{' '}
                <strong>{rate} / 5</strong>
              </p>
            </div>
          )}
          {comentario && (
            <div>
              <p>
                Comentario:
                <span className="ml-2">{comentario}</span>
              </p>
            </div>
          )}
        </div>
      </div>
    </li>
  )
}

export default ModalDetalleOrden
