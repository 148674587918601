import { Store } from 'layouts/Context'
import React, { useEffect, useState } from 'react'
import { useContext } from 'react'
import { useQueryClient } from 'react-query'
import { Link, useHistory, useParams } from 'react-router-dom'
import { Alert, Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import { productoService } from 'services/productoService'

const Form = [
  {
    name: 'categoria',
    label: 'Categoria',
    placeholder: 'Ingresa Categoria',
  },
  {
    name: 'clave',
    label: 'Clave',
    placeholder: 'Ingresa Clave',
  },
  {
    name: 'caracteristicas',
    label: 'Caracteristicas',
    placeholder: 'Ingresa Caracteristicas',
  },
  {
    name: 'departamento',
    label: 'Departamento',
    placeholder: 'Ingresa Departamento',
  },
  {
    name: 'descripcion',
    label: 'Descripcion',
    placeholder: 'Ingresa Descripcion',
  },
  {
    name: 'existencia',
    label: 'Existencia',
    placeholder: 'Ingresa Existencia',
  },
  {
    name: 'precio1',
    label: 'Precio',
    placeholder: 'Ingresa Precio',
  },
  {
    name: 'impuesto',
    label: '¿Incluir IVA?',
    helpText: 'Ingresar S o N',
  },
  {
    name: 'ieps',
    label: '¿Incluir IEPS?',
    helpText: 'Ingresar S o N',
  },
  {
    name: 'precio_descuento',
    label: 'Precio descuento',
    placeholder: 'Ingrese el precio de descuento',
    helpText: 'Ingrese 0 si el producto no tiene descuento',
  },
]
const Producto = () => {
  const queryClient = useQueryClient()
  const { createNotification } = useContext(Store)
  const { id } = useParams()
  const history = useHistory()
  const [state, setState] = useState({
    categoria: '',
    clave: '',
    componentes: '',
    departamento: '',
    descripcion: '',
    existencia: '',
    marca: '',
    precio1: '',
    precio_descuento: '',
    tipo: '',
  })
  const [imagen, setImagen] = useState('')
  const [prevImagen, setPrevImagen] = useState(null)

  useEffect(() => {
    const obtenerProducto = async () => {
      const { data } = await productoService.get(id)
      setState(data)
    }

    obtenerProducto()
  }, [history.location.state, id])

  const setDatosProducto = (ev) => {
    const { name, value } = ev.target
    setState({
      ...state,
      [name]: value,
    })
  }

  const guardar = async () => {
    try {
      await productoService.update(id, state)
      queryClient.invalidateQueries('productos')
      createNotification('info', 'Producto actualizado')
    } catch (error) {
      console.log(error)
      createNotification('error', 'Producto no actualizado')
    }
  }

  const onSelectFile = async (e) => {
    const getImgUrl = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)

        reader.onload = () => {
          resolve(reader.result)
        }

        reader.onerror = () => {
          reject('Error al momento de obtner la previsualización de la imagen')
        }
      })
    }

    const file = e.target.files[0]
    const base64 = await getImgUrl(file)

    setPrevImagen(base64)
    setImagen(file)
  }

  const revertirImagen = () => {
    setPrevImagen(null)
    setImagen(null)
  }

  const actualizarImagen = async () => {
    try {
      const formData = new FormData()
      formData.append('imagen', imagen)
      const { data } = await productoService.updateImagen(id, formData)
      setState({
        ...state,
        url: data.url,
      })

      setPrevImagen(null)

      queryClient.invalidateQueries('productos')
      createNotification('info', 'Imagen cargada')
    } catch (err) {
      console.log(err)
      createNotification('error', 'Error al momento de actualizar la imagen')
    }
  }

  return (
    <div className="row">
      <div className="col">
        <Card className="shadow">
          <CardHeader className="border-0">
            <div className="row align-items-center">
              <div className="col">
                <Link className="btn btn-secondary" to="/admin/productos">
                  <i className="fas fa-chevron-circle-left"></i> Regresar
                </Link>
                <h1 className="mb-0 mt-4">
                  Producto: <small> {state.descripcion}</small>
                </h1>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <div className="row">
              <div className="col-md-4">
                <div className="row">
                  {Form.map((item) => (
                    <div className="col-12" key={item.name}>
                      <div className="form-group">
                        <label htmlFor="">{item.label}</label>
                        <input
                          type="text"
                          className={`form-control ${item.className || ''}`}
                          name={[item.name]}
                          defaultValue={state[item.name]}
                          placeholder={item.label}
                          onChange={setDatosProducto}
                        />
                        {item.helpText && (
                          <small className="form-text text-muted">
                            {item.helpText}
                          </small>
                        )}
                      </div>
                    </div>
                  ))}
                  <div className="col-12">
                    <button
                      className="btn btn-success"
                      onClick={() => guardar()}
                    >
                      Actualizar
                    </button>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="form-row">
                  <div className="form-group col">
                    <label htmlFor="">Imagen</label>
                    <div className="input-group mb-3">
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="inputGroupFile01"
                          aria-describedby="inputGroupFileAddon01"
                          onChange={onSelectFile}
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="inputGroupFile01"
                        >
                          Seleccionar Imagen
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {state.url || prevImagen ? (
                  <>
                    {prevImagen && (
                      <Row>
                        <Col md={9}>
                          <Alert className="bg-white text-dark border border-dark">
                            <i className="fas fa-info-circle info mr-1"></i>
                            Esta a punto de actualizar la imagen del producto
                          </Alert>
                        </Col>
                        <Col md={3}>
                          <Button
                            className="btn btn-warning btn-lg btn-block"
                            onClick={revertirImagen}
                          >
                            <i className="fas fa-undo-alt"></i> Revertir imagen
                          </Button>
                        </Col>
                      </Row>
                    )}
                    <img
                      src={prevImagen ? prevImagen : state.url}
                      className="w-100 mb-2"
                      alt="..."
                    />
                  </>
                ) : (
                  <Alert className="bg-info">
                    <i className="fas fa-info-circle"></i> No hay foto
                    disponible para este producto.
                  </Alert>
                )}
                <button
                  title={
                    !prevImagen ? 'Selecciona la imagen para actualizar' : ''
                  }
                  disabled={!prevImagen}
                  className="btn btn-default"
                  onClick={() => actualizarImagen()}
                >
                  <i className="fas fa-upload"></i> Actualizar imagen
                </button>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

export default Producto
