import ErrorMessage from 'components/Form/ErrorHandler'
import Notificacion from 'components/Notificacion'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap'
import { authService } from 'services/authService'
import { sessionService } from 'services/sessionService'

const Login = () => {
  const history = useHistory()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const [loginError, setLoginError] = useState(null)

  const login = async (credenciales) => {
    try {
      const { usuario, password } = credenciales
      const { data } = await authService.login(usuario, password)

      const { access_token, exp } = data

      sessionService.save(access_token, exp)

      setLoginError(null)

      history.push('/admin/logistica')
    } catch (error) {
      let message = 'Error al momento de iniciar sesión, intente de nuevo'

      if (
        error.response &&
        error.response.data &&
        error.response.data.code === 'bad/credentials'
      ) {
        setLoginError('Verifique que el usuario y contraseña sean correctos')
      } else {
        setLoginError(message)
      }
    }
  }
  return (
    <Col lg="5" md="7">
      <Card className="bg-secondary shadow border-0">
        <CardHeader className="bg-transparent pb-5">
          <div className="text-center text-muted mb-2">
            <img alt="..." src={require('assets/img/brand/logo-header.svg')} />
            <br />
            <small>Utiliza tus credenciales para iniciar sesión</small>
          </div>
        </CardHeader>
        <CardBody className="px-lg-5 py-lg-5">
          {loginError && (
            <div className="mb-2">
              <small className="text-danger">
                <i className="fas fa-exclamation-triangle mr-1"></i>{' '}
                {loginError}
              </small>
            </div>
          )}
          <Form onSubmit={handleSubmit(login)} role="form">
            <FormGroup className="mb-3">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-email-83" />
                  </InputGroupText>
                </InputGroupAddon>
                <input
                  className="form-control"
                  {...register('usuario', {
                    required: 'El usuario es requerido',
                  })}
                  placeholder="Usuario"
                  type="email"
                />
              </InputGroup>
              <ErrorMessage errors={errors} name="usuario" />
            </FormGroup>
            <FormGroup>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-lock-circle-open" />
                  </InputGroupText>
                </InputGroupAddon>
                <input
                  className="form-control"
                  {...register('password', {
                    required: 'La contraseña es requerida',
                  })}
                  placeholder="Contraseña"
                  type="password"
                />
              </InputGroup>
              <ErrorMessage errors={errors} name="password" />
            </FormGroup>
            <div className="text-center">
              <Button className="my-4" color="primary" type="submit">
                Entrar
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
      <Notificacion />
    </Col>
  )
}

export default Login
