import React from 'react'
import ReactPaginate from 'react-paginate'

const Pagination = ({ pagesCount, onChangePage = () => {} }) => {
  return (
    <div>
      <ReactPaginate
        previousLabel={<i className="fas fa-chevron-left"></i>}
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        nextClassName="page-item"
        previousLinkClassName="page-link"
        nextLinkClassName="page-link"
        nextLabel={<i className="fas fa-chevron-right"></i>}
        breakLabel={<i className="fas fa-ellipsis-h mt-2 mx-2"></i>}
        breakClassName={'break-me'}
        pageCount={pagesCount}
        onPageChange={onChangePage}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
      />
    </div>
  )
}

export default Pagination
