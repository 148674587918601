import Table from 'components/Table'
import { createHeaders } from 'components/Table'
import LogisticaHook from 'hooks/LogisticaHook'
import { currency } from 'libs/utils'
import React, { useMemo } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { Card, CardBody, CardHeader } from 'reactstrap'

import LoadingMessage from './components/LoadingMessage'
import ModalDetalleOrden from './components/ModalDetalleOrden'
import RetryMessage from './components/RetryMessage'
import { calcularTotalOrden } from './utils'

const Logistica = () => {
  const {
    detalles,
    show,
    rowNuevo,
    rowAtendido,
    HandleAction,
    abrirDetalles,
    setShow,
    getOrdenes,
    seleccion,
  } = LogisticaHook()

  const queryClient = useQueryClient()
  const {
    data: ordenes,
    isLoading,
    error,
    refetch,
  } = useQuery('logistica', getOrdenes, {
    staleTime: 10000,
    refetchInterval: 60000,
  })

  const onHandleAction = async (estatus, orden) => {
    const ordenActualizada = await HandleAction(estatus, orden)

    if (ordenActualizada) {
      queryClient.invalidateQueries('ordenes')
    }
  }

  const headers = useMemo(
    () => [
      createHeaders('#Orden', 'conekta_order_id'),
      createHeaders('Cliente', 'nombre'),
      createHeaders('Domicilio', 'texto_direccion'),
      createHeaders('Total', 'total', (_, row) => {
        const { total } = calcularTotalOrden(row)

        return currency(total)
      }),
      createHeaders('Creado', 'creado'),
      createHeaders('Tipo de Pago', 'texto_tipo_pago'),
      createHeaders('Estatus', 'estatus'),
    ],
    []
  )

  if (isLoading) {
    return <LoadingMessage message="Cargando ordenes" />
  }

  if (error) {
    return <RetryMessage message="ordenes" refetch={refetch} />
  }

  return (
    <div className="row">
      <div className="col">
        <div className="row">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="row -items-center">
                  <div className="col">
                    <h3 className="mb-0 text-uppercase">Nuevas ordenes</h3>
                  </div>
                  <div className="col text-right">
                    {rowNuevo !== null && (
                      <Acciones
                        onHandleAction={onHandleAction}
                        rowNuevo={rowNuevo}
                        abrirDetalles={abrirDetalles}
                      />
                    )}
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <div className="row">
                  <div className="col">
                    {ordenes['nuevas'].length > 0 ? (
                      <Table
                        customClass="table-striped"
                        columns={headers}
                        data={ordenes['nuevas']}
                        labelCount="ordenes"
                        rowSelected={rowNuevo}
                        onSelectRow={(row) => seleccion(row.original)}
                      />
                    ) : (
                      <p>
                        <i className="fas fa-info-circle color-info"></i> No hay
                        nuevas ordenes que mostrar
                      </p>
                    )}
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="row align-items-center">
                  <div className="col">
                    <h3 className="mb-0 text-uppercase">Ordenes atendidas</h3>
                  </div>
                  <div className="col text-right">
                    {rowAtendido !== null && (
                      <AccionesAtendidos
                        onHandleAction={onHandleAction}
                        rowAtendido={rowAtendido}
                        abrirDetalles={abrirDetalles}
                      />
                    )}
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <div className="row">
                  <div className="col">
                    {ordenes['atendidas'].length > 0 ? (
                      <Table
                        customClass="table-striped"
                        labelCount="ordenes"
                        columns={headers}
                        data={ordenes['atendidas']}
                        rowSelected={rowAtendido}
                        onSelectRow={(row) => seleccion(row.original)}
                      />
                    ) : (
                      <p>
                        <i className="fas fa-info-circle color-info"></i> No hay
                        ordenes por entregar que mostrar
                      </p>
                    )}
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
      {detalles && (
        <ModalDetalleOrden
          detalles={detalles}
          show={show}
          onClose={() => setShow(false)}
        />
      )}
    </div>
  )
}

const Acciones = ({ onHandleAction, rowNuevo, abrirDetalles }) => {
  return (
    <div className="row">
      <div className="col-md-12 ">
        <div className="d-flex flex-row-reverse bd-highlight mb-3">
          <div className="p-2 bd-highlight">
            <button
              className="btn btn-block btn-danger btn-sm"
              onClick={() => onHandleAction('Cancelar', rowNuevo)}
            >
              Cancelar
            </button>
          </div>
          <div className="p-2 bd-highlight">
            <button
              className="btn btn-block btn-warning btn-sm"
              onClick={() => abrirDetalles(rowNuevo)}
            >
              Detalles
            </button>
          </div>
          {rowNuevo.tipo_pago !== 'Oxxo' &&
            rowNuevo.oxxo_estatus !== 'por pagar' && (
              <div className="p-2 bd-highlight">
                <button
                  className="btn btn-block btn-info btn-sm"
                  onClick={() => onHandleAction('Atendido', rowNuevo)}
                >
                  Atendido
                </button>
              </div>
            )}
        </div>
      </div>
    </div>
  )
}

const AccionesAtendidos = ({ onHandleAction, rowAtendido, abrirDetalles }) => {
  return (
    <div className="row">
      <div className="col-md-12 ">
        <div className="d-flex flex-row-reverse bd-highlight mb-3">
          <div className="p-2 bd-highlight">
            <button
              className="btn btn-block btn-danger btn-sm"
              onClick={() => onHandleAction('Cancelar', rowAtendido)}
            >
              Cancelar
            </button>
          </div>
          <div className="p-2 bd-highlight">
            <button
              className="btn btn-block btn-warning btn-sm"
              onClick={() => abrirDetalles(rowAtendido)}
            >
              Detalles
            </button>
          </div>
          <div className="p-2 bd-highlight">
            <button
              className="btn btn-block btn-info btn-sm"
              onClick={() => onHandleAction('Entregado', rowAtendido)}
            >
              Entregado
            </button>
          </div>
          <div className="p-2 bd-highlight">
            <button
              className="btn btn-block btn-success btn-sm"
              onClick={() => onHandleAction('Enviado', rowAtendido)}
            >
              Enviado
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Logistica
