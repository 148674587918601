import { read, utils, writeFileXLSX } from 'xlsx'

const getDatafromExcel = (file) => {
  return new Promise((resolve, reject) => {
    const workbook = read(file, { type: 'array' })

    const worksheet = workbook.Sheets[workbook.SheetNames[0]]
    const excelData = utils.sheet_to_json(worksheet, {
      blankrows: true,
    })
    resolve(excelData)
  })
}

const exportToExcel = (name = 'Nueva_Hoja', data) => {
  const fileName = `${name}.xlsx`
  const ws = utils.json_to_sheet(data)
  const wb = utils.book_new()
  utils.book_append_sheet(wb, ws, 'Nueva_Hoja')

  writeFileXLSX(wb, fileName)
}

export { getDatafromExcel, exportToExcel }
