import {
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Switch as SwitchMaterial,
} from '@material-ui/core'
import ErrorMessage from 'components/Form/ErrorHandler'
import { isNumber, noExpiredDates } from 'libs/formValidators'
import { debounce } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form'
import {
  Button,
  Col,
  FormText,
  InputGroup,
  InputGroupText,
  Row,
} from 'reactstrap'

import LoadingMessage from '../components/LoadingMessage'
import ProductosSelector from './components/ProductosSelector'
import { useGetDataForCupons, useVerifyCodigo } from './hooks'

const CuponForm = ({
  id = null,
  onSave,
  defaultValues = {
    es_porcentaje: false,
    envio_gratis: false,
    primera_compra: false,
  },
}) => {
  const { productos, categorias, isLoadingCategorias, isLoadingProductos } =
    useGetDataForCupons()

  const [esPorcentaje, setEsPorcentaje] = useState(
    defaultValues.es_porcentaje || ''
  )
  const [tipoSeleccion, setTipoSeleccion] = useState(
    defaultValues.tipo_seleccion || ''
  )
  const [tipoCupon, setTipoCupon] = useState(
    defaultValues.tipo_descuento || 'descuento'
  )
  const [codigo, setCodigo] = useState(defaultValues.codigo || '')

  const debounceCodigo = useRef(
    debounce((newCodigo) => {
      setCodigo(newCodigo)
    }, 1000)
  ).current

  const { codigoVerification, onType } = useVerifyCodigo(
    id,
    codigo,
    defaultValues
  )

  const methods = useForm({
    defaultValues,
  })

  const {
    formState: { errors: formErrors },
    handleSubmit,
    register,
    setValue,
  } = methods

  const onChangeTipoCupon = (value) => {
    setValue('descuento', 0)
    return setTipoCupon(value)
  }

  const onChangeCodigo = (value) => {
    debounceCodigo(value)
    onType()
  }

  const handleOnSave = (data) => {
    if (codigoVerification.isValid) {
      onSave(data)
    }
  }

  useEffect(() => {
    return () => {
      debounceCodigo.cancel()
    }
  }, [debounceCodigo])

  if (isLoadingCategorias || isLoadingProductos) {
    return <LoadingMessage message="Se esta cargando el formulario" />
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleOnSave)}>
        <h4 className="text-muted">Detalle del cupón</h4>
        <hr className="mt-1" />
        <Row>
          <Col>
            <FormGroup>
              <FormLabel>Tipo de cupón</FormLabel>
              <select
                {...register('tipo_cupon', {
                  required: 'El tipo de cupón es requerido',
                })}
                onChange={(event) => onChangeTipoCupon(event.target.value)}
                className="form-control"
              >
                <option value="descuento" defaultChecked>
                  Descuento
                </option>
                <option value="2x1">2x1</option>
                <option value="3x2">3x2</option>
              </select>
              <ErrorMessage errors={formErrors} name="tipo_cupon" />
            </FormGroup>
          </Col>

          <Col>
            <FormGroup>
              <FormLabel>Nombre</FormLabel>
              <input
                className="form-control"
                {...register('nombre', {
                  required: 'el campo nombre es requerido',
                })}
              />
              <ErrorMessage errors={formErrors} name="nombre" />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <FormLabel>Código</FormLabel>
              <InputGroup>
                <input
                  className="form-control"
                  {...register('codigo', {
                    required: 'El campo código es requerido',
                    minLength: {
                      message: 'Se requiren como mínimo 6 caracteres',
                      value: 6,
                    },
                  })}
                  onChange={(e) => onChangeCodigo(e.target.value)}
                />
                <InputGroupText>
                  <CodigoIcon
                    codigo={codigo}
                    isLoading={codigoVerification.isLoading}
                    isValid={codigoVerification.isValid}
                    typing={codigoVerification.typing}
                  />
                </InputGroupText>
              </InputGroup>
              {codigo !== '' &&
                !codigoVerification.typing &&
                !codigoVerification.isValid &&
                !codigoVerification.isLoading && (
                  <small className="mt-2">
                    <i className="fas fa-exclamation-triangle mr-1"></i>
                    El código ya esta utilizado en otro cupón.
                  </small>
                )}
              <ErrorMessage errors={formErrors} name="codigo" />
            </FormGroup>
          </Col>
          <Col className={tipoCupon === 'descuento' ? '' : 'd-none'}>
            <FormGroup>
              <FormLabel>Descuento</FormLabel>
              <InputGroup>
                {!esPorcentaje && (
                  <InputGroupText className="mr-1">$</InputGroupText>
                )}
                <input
                  className="form-control"
                  {...register('descuento', {
                    required: 'El campo descuento es requerido',
                    validate: {
                      esNumero: (v) => {
                        return (
                          !isNaN(Number(v)) || 'Debes ingresar solo numeros'
                        )
                      },
                      limiteNumero: (v) => {
                        return (
                          !esPorcentaje ||
                          (esPorcentaje && Number(v) <= 100) ||
                          'El valor no puede superar el 100%'
                        )
                      },
                    },
                  })}
                />
                {esPorcentaje && (
                  <InputGroupText className="mr-1">%</InputGroupText>
                )}
              </InputGroup>
              <ErrorMessage errors={formErrors} name="descuento" />
              <Switch
                rules={{
                  validate: {
                    isNumber,
                  },
                }}
                className="mt-1"
                name="es_porcentaje"
                label="¿Es porcentaje?"
                onChangeValue={(value) => {
                  setValue('descuento', 0)
                  setValue('maximo_descuento', 0)
                  setEsPorcentaje(value)
                }}
              />
            </FormGroup>
          </Col>
        </Row>

        <h4 className="mt-5 text-muted">Reglas</h4>
        <hr className="mt-1" />
        <Row>
          <Col md={4}>
            <FormGroup>
              <FormLabel>¿Tiene envió gratis?</FormLabel>
              <Switch name="envio_gratis" />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <FormLabel>¿Solo primera compra?</FormLabel>
              <Switch name="primera_compra" />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <FormLabel>Mínimo de compra</FormLabel>
              <InputGroup>
                <InputGroupText className="mr-1">$</InputGroupText>
                <input
                  className="form-control"
                  {...register('compra_minima', {
                    required: 'El mínimo de compra es requerido',
                    validate: {
                      isNumber,
                    },
                  })}
                />
              </InputGroup>

              <ErrorMessage errors={formErrors} name="compra_minima" />
            </FormGroup>
          </Col>
          {esPorcentaje && (
            <Col>
              <FormGroup>
                <FormLabel>Máximo descuento</FormLabel>
                <InputGroup>
                  <InputGroupText className="mr-1">$</InputGroupText>
                  <input
                    className="form-control"
                    {...register('maximo_descuento', {
                      required: 'El máxima de descuento es requerido',
                      valueAsNumber: true,
                      validate: {
                        isNumber,
                      },
                    })}
                  />
                </InputGroup>
                <FormText>
                  Limite máximo de decuento (Aplica solo porcentajes)
                </FormText>
                <ErrorMessage errors={formErrors} name="maximo_descuento" />
              </FormGroup>
            </Col>
          )}
          <Col>
            <FormGroup>
              <FormLabel>Fecha de vencimiento</FormLabel>
              <InputGroup>
                <input
                  className="form-control"
                  type="date"
                  {...register('vigencia', {
                    required: 'La fecha de vencimiento es requerido',
                    validate: {
                      noExpiredDates,
                    },
                  })}
                />
              </InputGroup>
              <ErrorMessage errors={formErrors} name="vigencia" />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <FormLabel>Tipo de selección</FormLabel>
              <select
                {...register('tipo_seleccion', {
                  required: 'El tipo de selección es requerida',
                })}
                className="form-control"
                onChange={(event) => setTipoSeleccion(event.target.value)}
              >
                <option value="">Selecciona una opción</option>
                <option value="productos">Productos</option>
                <option value="categoria">Categoria</option>
                <option value="todos">
                  Todos (Aplica para cualquier producto)
                </option>
              </select>
              <ErrorMessage errors={formErrors} name="tipo_seleccion" />
            </FormGroup>
          </Col>
          <Col md={12} className="mt-2">
            {tipoSeleccion === 'categoria' && (
              <FormGroup>
                <FormLabel>Categoría</FormLabel>
                <select
                  className="form-control"
                  {...register('categoria', {
                    required: 'La categoria es requerida',
                  })}
                >
                  <option value="">Selecciona una opción</option>
                  {categorias.map((categoria, index) => (
                    <option key={index} value={categoria}>
                      {categoria}
                    </option>
                  ))}
                </select>
              </FormGroup>
            )}

            {tipoSeleccion === 'productos' && (
              <FormGroup>
                <FormLabel>Productos seleccionados</FormLabel>
                <ProductosSelector productos={productos} />
                <FormHelperText>
                  Se requiere al menos 1 producto.
                </FormHelperText>
                <ErrorMessage errors={formErrors} name="productos" />
              </FormGroup>
            )}
          </Col>
        </Row>

        <Button className="mt-3" color="primary">
          <i className="fas fa-save mr-1"></i>
          Guardar
        </Button>
      </form>
    </FormProvider>
  )
}

const Switch = ({
  name,
  onChangeValue = () => {},
  label = '',
  className = '',
}) => {
  const { control } = useFormContext()
  return (
    <div className={className}>
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange, ref } }) => {
          return (
            <FormControlLabel
              control={
                <SwitchMaterial
                  name={name}
                  ref={ref}
                  defaultChecked={value}
                  onChange={(event) => {
                    onChange(event.target.checked)
                    onChangeValue(event.target.checked)
                  }}
                />
              }
              label={label}
            ></FormControlLabel>
          )
        }}
      ></Controller>
    </div>
  )
}

const CodigoIcon = ({ codigo, isLoading, isValid, typing }) => {
  let icon = 'fas fa-exclamation-circle'

  if (codigo) {
    if (typing) {
      icon = 'fas fa-keyboard'
    } else if (isLoading) {
      icon = 'fas fa-spinner fa-pulse'
    } else {
      icon = `fas ${
        isValid ? 'fa-check-circle text-success' : 'fa-times-circle text-danger'
      }`
    }
  }

  return <i className={icon}></i>
}

export default CuponForm
