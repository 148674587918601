import React from 'react'
import { Button, Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap'

const ModalComponent = ({
  title,
  toggle,
  isOpen,
  children,
  footer,
  success_text,
  cancel_text,
  handleSuccess,
  handleCancel,
  disabledSuccessButton = false,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalBody>
        <Row className="mb-3">
          <Col md={10}>
            <h2>{title}</h2>
          </Col>
          <Col md={2} className="text-right">
            <Button className="btn btn-white" onClick={toggle}>
              <i className="fas fa-times"></i>
            </Button>
          </Col>
        </Row>
        {children}
      </ModalBody>
      {footer && (
        <ModalFooter>
          <Button
            color="primary"
            onClick={handleSuccess}
            disabled={disabledSuccessButton}
          >
            {success_text}
          </Button>{' '}
          {cancel_text && (
            <Button color="secondary" onClick={handleCancel}>
              {cancel_text}
            </Button>
          )}
        </ModalFooter>
      )}
    </Modal>
  )
}

export default ModalComponent
