import { createNotification } from 'components/Notificacion'
import { useCallback, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { categoriaService } from 'services/categoriaService'
import { cuponService } from 'services/cuponService'
import { productoService } from 'services/productoService'

export const useGetDataForCupons = () => {
  const getCategorias = async () => {
    const { data } = await categoriaService.getAll()

    return data
  }

  const getProductos = async () => {
    const { data } = await productoService.getAllMinified()

    return data.map((producto) => {
      return {
        value: producto.id,
        label: producto.nombre,
      }
    })
  }

  const { data: categorias = [], isLoading: isLoadingCategorias } = useQuery(
    'categorias',
    getCategorias,
    {
      staleTime: Infinity,
    }
  )
  const { data: productos = [], isLoading: isLoadingProductos } = useQuery(
    'listaProductos',
    getProductos,
    {
      staleTime: Infinity,
    }
  )

  return {
    productos,
    categorias,
    isLoadingCategorias,
    isLoadingProductos,
  }
}

export const useVerifyCodigo = (id, codigo, defaultValues = {}) => {
  const [codigoVerification, setCodigoVerification] = useState({
    isValid: false,
    isLoading: false,
    typing: defaultValues.codigo || false,
  })

  const verifyCodigo = useCallback(async (id, codigo) => {
    try {
      setCodigoVerification({
        isLoading: true,
        isValid: false,
        typing: false,
      })

      const {
        data: { exists },
      } = await cuponService.verify(id, codigo)

      setCodigoVerification({
        isLoading: false,
        isValid: !exists,
        typing: false,
      })
    } catch (err) {
      createNotification(
        'error',
        'Error al consultar la disponibilidad del código'
      )
      setCodigoVerification({
        isLoading: false,
        isValid: false,
        typing: false,
      })
    }
  }, [])

  const onType = () => {
    setCodigoVerification({
      isValid: false,
      isLoading: false,
      typing: true,
    })
  }

  useEffect(() => {
    if (codigo.length >= 6) {
      verifyCodigo(id, codigo)
    }
  }, [id, codigo, verifyCodigo])

  return {
    codigoVerification,
    onType,
  }
}
