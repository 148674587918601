/**
 * Calcula el total de la orden aplicando descuentos e envios gratis si estos los incluye.
 * @param {Object} orden
 * @returns retorna un objecto con el total y el total original
 */
export const calcularTotalOrden = (orden) => {
  const { monto, costo_envio, descuento, envio_gratis } = orden

  let total = monto + costo_envio
  const total_original = total

  if (descuento) {
    total -= descuento

    if (envio_gratis) {
      total -= costo_envio
    }
  }

  return {
    total,
    total_original,
  }
}
