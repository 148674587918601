import React from 'react'
import { FormGroup, Input } from 'reactstrap'

const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <FormGroup>
      <Input
        value={filter || ''}
        onChange={(event) => {
          setFilter(event.target.value)
        }}
        placeholder="Buscar"
      />
    </FormGroup>
  )
}

export default GlobalFilter
