import Loader from 'components/Loader'
import ModalComponent from 'components/Modal/ModalComponent'
import { createNotification } from 'components/Notificacion'
import Table, { createHeaders } from 'components/Table'
import { exportToExcel } from 'hooks/ExcelHook'
import ModalHook from 'hooks/ModalHook'
import ProductosHook from 'hooks/ProductosHook'
import React, { useMemo } from 'react'
import { useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { Alert, Card, CardBody, CardHeader } from 'reactstrap'

const Productos = () => {
  const [show, setShow] = ModalHook()
  const [failedProductos, setFailedProductos] = useState([])
  const {
    subirProductos,
    leerExcel,
    visible,
    getProductos,
    disableCargar,
    errorExcel,
    resetCargaExcel,
  } = ProductosHook()
  const headers = useMemo(
    () => [
      createHeaders('Categoria', 'categoria'),
      createHeaders('Imagen', 'imagen'),
      createHeaders('Clave', 'clave'),
      createHeaders('Precio', 'precio1'),
      createHeaders('Departamento', 'departamento'),
      createHeaders('Descripcion', 'descripcion'),
      createHeaders('Existencia', 'existencia'),
      createHeaders('Editar', 'editar'),
    ],
    []
  )

  const headersFailedUploadTable = useMemo(
    () => [
      createHeaders('Categoria', 'categoria'),
      createHeaders('Clave', 'clave'),
      createHeaders('Precio', 'precio1'),
      createHeaders('Departamento', 'departamento'),
      createHeaders('Descripcion', 'descripcion'),
      createHeaders('Existencia', 'existencia'),
    ],
    []
  )

  const queryClient = useQueryClient()

  const { data: productos, isLoading } = useQuery(['productos'], getProductos, {
    staleTime: 300000,
  })

  const downloadExcel = () => {
    const data = productos.map((producto) => {
      return {
        clave: producto.clave,
        descripcion: producto.descripcion,
        precio1: producto.precio1,
        precio2: producto.precio2,
        precio3: producto.precio3,
        existencia: producto.existencia,
        peso: producto.peso,
        caracteristicas: producto.caracteristicas,
        departamento: producto.departamento,
        categoria: producto.categoria,
        impuesto: producto.impuesto,
        ieps: producto.ieps,
        precio_descuento: producto.precio_descuento,
        tipo: producto.tipo,
        componentes: producto.componentes,
        marca: producto.marca,
        destacado: producto.destacado,
      }
    })

    exportToExcel('productos', data)
  }

  const handleCargaProductos = async () => {
    const productos = await subirProductos()

    if (productos.failed.length > 0) {
      createNotification(
        'warning',
        'Hubo productos que no se cargaron, verifique el formato del excel'
      )

      setFailedProductos(productos.failed)
    } else {
      setShow(false)
      createNotification('success', 'Se cargaron los productos exitosamente')
      setFailedProductos([])
    }

    queryClient.invalidateQueries('productos')
  }

  const onClose = () => {
    resetCargaExcel()
    setShow(false)
  }

  return (
    <div className="row">
      <div className="col">
        <Card className="shadow">
          <CardHeader className="border-0">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="mb-0 text-uppercase">Lista de productos</h3>
              </div>
              <div className="col text-right">
                <button
                  className="btn btn-success btn-sm"
                  onClick={() => setShow(true)}
                >
                  <i className="fas fa-upload"></i> Cargar Excel
                </button>
                <button
                  className="btn btn-default btn-sm"
                  onClick={() => downloadExcel()}
                >
                  <i className="fas fa-download"></i> Descargar Excel
                </button>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            {!isLoading ? (
              <>
                {productos.length > 0 ? (
                  <Table
                    customClass="table-striped"
                    columns={headers}
                    data={productos}
                    labelCount="productos"
                    order={['descripcion', 'desc']}
                  />
                ) : (
                  <p>
                    <i className="fas fa-info-circle"></i> No hay productos que
                    mostrar
                  </p>
                )}
              </>
            ) : (
              <div className="text-center my-3">
                <h3>
                  <i className="fas fa-spinner fa-pulse"></i> Cargando productos
                </h3>
              </div>
            )}
          </CardBody>
        </Card>
      </div>
      <ModalComponent
        title="Cargar Excel de productos"
        toggle={onClose}
        isOpen={show}
        footer={true}
        success_text="Subir"
        cancel_text="Cancelar"
        handleSuccess={handleCargaProductos}
        handleCancel={onClose}
        disabledSuccessButton={visible || disableCargar}
      >
        <div className="container">
          {errorExcel && (
            <div className="alert alert-danger">
              <i className="fas fa-info-circle mr-1"></i>
              El excel ingresado no es compatible, por favor revise el formato.
            </div>
          )}
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label htmlFor="">Selecciona un achivo excel</label>
                <input
                  type="file"
                  className="form-control"
                  onChange={leerExcel}
                />
              </div>
            </div>
          </div>
          <Loader show={visible} />
          {!visible && failedProductos.length > 0 && (
            <>
              <hr />
              <h3 className="text-uppercase">Productos no procesados</h3>
              <Alert className="alert-warning">
                <i className="fas fa-info-circle"></i> Verifique el formato del
                excel
              </Alert>

              <Table
                columns={headersFailedUploadTable}
                data={failedProductos}
                labelCount="productos"
              />
            </>
          )}
        </div>
      </ModalComponent>
    </div>
  )
}

export default Productos
