const isNumber = (v) => {
  return !Number.isNaN(v) || 'Debe ingresar un número'
}

const noExpiredDates = (v) => {
  const date = new Date(`${v} 23:59`) // Covers all day

  const currentDate = new Date()
  currentDate.setHours(0, 0, 0) // From the begin of the day

  return (
    date.getTime() >= currentDate.getTime() ||
    'Solo se permiten fechas en presente o en futuro'
  )
}

export { isNumber, noExpiredDates }
