import { createNotification } from 'components/Notificacion'
import React, { createContext, useEffect, useState } from 'react'
import { NotificationContainer } from 'react-notifications'
import 'react-notifications/lib/notifications.css'

export const Store = createContext({
  createNotification: () => {},
})

const Context = ({ ...props }) => {
  const { children } = props

  const [exp_mili] = useState(
    localStorage.hasOwnProperty('exp_mili') ? localStorage.exp_mili : null
  )

  useEffect(() => {
    if (exp_mili < new Date().getTime()) {
      if (window.location.pathname !== '/auth/login') {
        localStorage.clear()
        window.location.href = '/auth/login'
      }
    }
  }, [exp_mili])

  return (
    <Store.Provider
      value={{
        createNotification,
      }}
    >
      {children}
      <NotificationContainer />
    </Store.Provider>
  )
}

export default Context
