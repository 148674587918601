import React from 'react'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import 'react-notifications/lib/notifications.css'

export const createNotification = (type, message, title) => {
  switch (type) {
    case 'info':
      NotificationManager.info(message)
      break
    case 'success':
      NotificationManager.success(message, title)
      break
    case 'warning':
      NotificationManager.warning(message, title)
      break
    case 'error':
      NotificationManager.error(message, title)
      break
    default:
      throw Error('El tipo de notificación no esta disponible')
  }
}

const Notificacion = () => {
  return <NotificationContainer />
}

export default Notificacion
