import { Controller } from 'react-hook-form'
import AsyncSelect from 'react-select/async'

const ProductosSelector = ({ productos }) => {
  const getOptions = async (input) => {
    return new Promise((resolve, reject) => {
      const options = []

      for (const producto of productos) {
        if (producto.label.toLowerCase().includes(input.toLowerCase())) {
          options.push(producto)
        }

        if (options.length >= 21) {
          break
        }
      }

      resolve(options)
    })
  }

  return (
    <Controller
      name="productos"
      rules={{
        validate: {
          leastOneProducto: (v) =>
            (Array.isArray(v) && v.length > 0) ||
            'Se requiere al menos un producto',
        },
      }}
      render={({ field: { value, onChange } }) => {
        const handleOnChange = (newValue) => {
          onChange(newValue)
        }

        return (
          <AsyncSelect
            placeholder="Seleccionar productos"
            value={value}
            isMulti
            isClearable
            defaultOptions={productos.slice(0, 20)}
            onChange={handleOnChange}
            loadOptions={getOptions}
          />
        )
      }}
    ></Controller>
  )
}

export default ProductosSelector
