/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
// reactstrap components
import { Container, Row } from 'reactstrap'
// core components
import routes from 'routes.js'

class Auth extends React.Component {
  componentDidMount() {
    document.body.classList.add('bg-default')
  }
  componentWillUnmount() {
    document.body.classList.remove('bg-default')
  }
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === '/auth') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        )
      } else {
        return null
      }
    })
  }
  render() {
    return (
      <>
        <div className="main-content">
          <div className="header bg-yellow py-7 py-lg-8"></div>
          <Container className="mt--8 pb-5">
            <Row className="justify-content-center">
              <Switch>
                {this.getRoutes(routes)}
                <Redirect from="*" to="/auth/login" />
              </Switch>
            </Row>
          </Container>
        </div>
      </>
    )
  }
}

export default Auth
