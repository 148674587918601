import Swal from 'sweetalert2'

/**
 * Utility to map a number value to currency.
 * @param {Nunber} value
 * @param {String} locale
 * @param {String} currency
 * @returns
 */
export const currency = (value, currency = 'MXN') => {
  return Intl.NumberFormat(undefined, {
    currency,
    style: 'currency',
  }).format(value)
}

/**
 * Utily to format dates
 * @param {string} value
 * @param {Intl.DateTimeFormatOptions} options
 * @returns The date with the format provided.
 */
export const dateFormat = (value, options = { dateStyle: 'medium' }) => {
  return Intl.DateTimeFormat(undefined, options).format(new Date(value))
}

export const replaceBooleanToWord = (value) => {
  return Boolean(value) ? 'Sí' : 'No'
}

export const confirmMessage = async (title, message) => {
  const resp = await Swal.fire({
    title,
    html: `${message}\n¿Esta seguro/a de esta operación?`,
    showCancelButton: true,
    confirmButtonText: 'Sí, continuar',
    cancelButtonText: 'Cancelar',
  })

  return resp.isConfirmed
}
