import { ErrorMessage as FormErrorMessage } from '@hookform/error-message'
import React from 'react'
import { FormText } from 'reactstrap'

function ErrorMessage({ errors, name }) {
  return (
    <FormErrorMessage
      errors={errors}
      name={name}
      render={({ message }) => (
        <FormText className="mt-1">
          <i className="fas fa-exclamation-triangle mr-1"></i> {message}
        </FormText>
      )}
    />
  )
}

export default ErrorMessage
