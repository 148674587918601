import { HTTP } from './network'

export const productoService = {
  getAll() {
    return HTTP.get('/productos')
  },
  getAllMinified() {
    return HTTP.get('/productos/minified')
  },
  get(id) {
    return HTTP.get('/producto/' + id)
  },
  upsert(data) {
    return HTTP.post('/producto', data)
  },
  update(id, data) {
    return HTTP.put('/producto/' + id, data)
  },
  updateImagen(id, data) {
    return HTTP.post(`/producto/${id}/image`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
}
