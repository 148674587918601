import React from 'react'
import { useGlobalFilter, usePagination, useTable } from 'react-table'
import { Col, Row } from 'reactstrap'

import GlobalFilter from './GlobalFilter'
import Pagination from './Pagination'

const Table = ({
  columns,
  data,
  labelCount = 'registros',
  onSelectRow = () => {},
  rowSelected = null,
  customClass = '',
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    pageCount,
    prepareRow,
    nextPage,
    canNextPage,
    previousPage,
    canPreviousPage,
    gotoPage,
    state,
    setGlobalFilter,
  } = useTable({ columns, data }, useGlobalFilter, usePagination)

  const { globalFilter } = state

  const onChangePage = ({ selected }) => {
    gotoPage(selected)
  }

  return (
    <div>
      <Row>
        <Col md={8}>
          <p>
            Resultados:{' '}
            <strong className="font-weight-bolder">
              {data.length} {labelCount}
            </strong>
          </p>
        </Col>
        <Col md={4}>
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
        </Col>
      </Row>
      <table
        className={'table table-responsive table-hover ' + customClass}
        style={{ width: '100% !important' }}
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row)

            return (
              <tr
                className={
                  rowSelected && rowSelected.id === row.original.id
                    ? 'bg-success text-white'
                    : ''
                }
                onClick={() => onSelectRow(row)}
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      <div className="d-flex justify-content-center mt-3">
        <Pagination
          pagesCount={pageCount}
          onChangePage={onChangePage}
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          next={nextPage}
          previous={previousPage}
        />
      </div>
    </div>
  )
}

export const createHeaders = (title, field, render = null) => {
  const columns = {
    Header: title,
    accessor: field,
  }

  if (typeof render === 'function') {
    columns.Cell = (props) =>
      render(props, props.cell.row.original, props.cell.row.original[field])
  }

  return columns
}

export default Table
