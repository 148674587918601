import { createNotification } from 'components/Notificacion'
import Table from 'components/Table'
import { createHeaders } from 'components/Table'
import { confirmMessage } from 'libs/utils'
import { replaceBooleanToWord } from 'libs/utils'
import { dateFormat } from 'libs/utils'
import { currency } from 'libs/utils'
import React, { useCallback, useMemo } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { Link, useHistory } from 'react-router-dom'
import { Badge, Button } from 'reactstrap'
import { cuponService } from 'services/cuponService'

import PageContainer from './components/PageContainer'

const Cupones = () => {
  const history = useHistory()
  const queryClient = useQueryClient()

  const onDelete = useCallback(
    async (cupon) => {
      const resp = await confirmMessage(
        'Eliminar cupón',
        `<p>
      Esta a punto de eliminar el cupon <strong>${cupon.nombre}</strong>
    </p>`
      )

      if (resp) {
        try {
          await cuponService.delete(cupon.id)
          createNotification('success', 'Cupón eliminado con éxito')
          queryClient.invalidateQueries('cupones')
        } catch (err) {
          createNotification('error', 'Error al eliminar el cupón')
        }
      }
    },
    [queryClient]
  )

  const columns = useMemo(() => {
    const formatDate = (date) => {
      return dateFormat(date, {
        dateStyle: 'medium',
        timeStyle: 'short',
        hour12: true,
      })
    }

    return [
      createHeaders('Nombre', 'nombre'),
      createHeaders('Código', 'codigo', (_, row, field) => {
        return <strong>#{field}</strong>
      }),
      createHeaders('Tipo de cupón', 'tipo_cupon', (_, row, field) => {
        return <span className="text-uppercase">{field}</span>
      }),
      createHeaders('Descuento', 'descuento', (_, row, field) => {
        const { es_porcentaje } = row

        if (field <= 0) {
          return (
            <Badge
              style={{ fontSize: 12, fontWeight: '800', color: 'dark-gray' }}
              color="secondary"
            >
              No aplica
            </Badge>
          )
        }

        return (
          <Badge
            style={{ fontSize: 12, fontWeight: '800' }}
            color={es_porcentaje ? 'primary' : 'success'}
            size="lg"
          >
            {es_porcentaje ? `${field}%` : currency(field)}
          </Badge>
        )
      }),
      createHeaders('Envió gratis', 'envio_gratis', (_, row, field) => {
        return replaceBooleanToWord(field)
      }),
      createHeaders('Compra mínima', 'compra_minima', (_, row, field) => {
        return currency(field)
      }),
      createHeaders('Primera compra', 'primera_compra', (_, row, field) => {
        return replaceBooleanToWord(field)
      }),

      createHeaders('Tipo selección', 'tipo_seleccion', (_, row, field) => {
        const { categoria } = row

        return (
          <span className="text-uppercase text-bolder">
            {field} {field === 'categoria' ? categoria : ''}
          </span>
        )
      }),
      createHeaders('Fecha vencimiento', 'vigencia', (_, row, field) => {
        const vencimiento = new Date(field)
        vencimiento.setHours(23, 59, 0, 0, 0)

        return formatDate(vencimiento.getTime())
      }),
      createHeaders('Última actualización', 'updated_at', (_, row, field) => {
        return formatDate(field)
      }),
      createHeaders('Fecha creado', 'created_at', (_, row, field) => {
        return formatDate(field)
      }),
      createHeaders('Acciones', 'acciones', (_, row) => {
        return (
          <>
            <Link
              to={`/admin/cupon/${row.id}`}
              className="btn btn-warning btn-sm"
            >
              <i className="fas fa-pencil-alt mr-1"></i>
              Editar
            </Link>
            <Button onClick={() => onDelete(row)} size="sm" color="danger">
              <i className="fas fa-trash-alt mr-1"></i>
              Eliminar
            </Button>
          </>
        )
      }),
    ]
  }, [onDelete])

  const getCupones = async () => {
    const { data } = await cuponService.getAll()

    return data
  }

  const { data, error, isLoading, refetch } = useQuery('cupones', getCupones, {
    staleTime: Infinity,
  })

  return (
    <PageContainer
      title="Cupones"
      error={error}
      isLoading={isLoading}
      refetch={refetch}
      actions={
        <>
          <Button color="success" onClick={() => history.push('/admin/cupon')}>
            <i className="fas fa-plus mr-1"></i>
            Nuevo cupón
          </Button>
        </>
      }
    >
      <Table columns={columns} data={data} />
    </PageContainer>
  )
}

export default Cupones
