import React from 'react'
import { Button, Card, CardBody } from 'reactstrap'

const RetryMessage = ({ message, refetch }) => {
  return (
    <Card>
      <CardBody>
        <h3>
          <i className="fas fa-exclamation-triangle"></i> Error al consultar los
          datos
        </h3>
        <p>
          Ocurrio un error inesperado al momento de obtener los resultados de{' '}
          {message}
        </p>
        <Button onClick={refetch}>
          <i className="fas fa-redo mr-1"></i>
          Reintentar
        </Button>
      </CardBody>
    </Card>
  )
}

export default RetryMessage
