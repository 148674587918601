import React from 'react'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap'

import LoadingMessage from './LoadingMessage'
import RetryMessage from './RetryMessage'

function PageContainer({
  title,
  actions,
  isLoading = false,
  error,
  refetch,
  children,
  className,
  loadingMessage = undefined,
}) {
  if (isLoading) {
    return <LoadingMessage message={loadingMessage} />
  }

  if (error) {
    return <RetryMessage message="cupones" refetch={refetch} />
  }

  return (
    <Card className={`${className} shadow`}>
      <CardHeader>
        <Row>
          <Col>
            <h2 className="text-uppercase">{title}</h2>
          </Col>
          {actions && <Col className="text-right">{actions}</Col>}
        </Row>
      </CardHeader>
      <CardBody>{children}</CardBody>
    </Card>
  )
}

export default PageContainer
