import { HTTP } from './network'

export const authService = {
  login(email, password) {
    return HTTP.post('/login', {
      email,
      password,
    })
  },
  getCsrfToken() {
    return HTTP.get('sanctum/csrf-cookie', {
      baseURL: process.env.REACT_APP_API_URL,
    })
  },
}
