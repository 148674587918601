import { HTTP } from './network'

export const ordenService = {
  getAll() {
    return HTTP.get('/ordenes')
  },
  getLogistica() {
    return HTTP.get('/orden/logistica')
  },
  update(id, estatus) {
    return HTTP.put('/orden/' + id, {
      estatus,
    })
  },
  getDetalle(id) {
    return HTTP.get('/orden/' + id)
  },
}
