import Login from 'views/pages/Auth/Login'
import CuponAdd from 'views/pages/Cupon/CuponAdd'
import CuponEdit from 'views/pages/Cupon/CuponEdit'
import Cupones from 'views/pages/Cupones'
import Logistica from 'views/pages/Logistica'
import Ordenes from 'views/pages/Ordenes'
import Producto from 'views/pages/Producto'

import Productos from './views/pages/Productos'

const routes = [
  {
    path: '/productos',
    name: 'Productos',
    icon: 'fas fa-shopping-basket text-yellow',
    component: Productos,
    layout: '/admin',
    menu: true,
  },
  {
    path: '/logistica',
    name: 'Logistica',
    icon: 'fas fa-truck text-yellow',
    component: Logistica,
    layout: '/admin',
    menu: true,
  },
  {
    path: '/ordenes',
    name: 'Ordenes',
    icon: 'fas fa-list text-yellow',
    component: Ordenes,
    layout: '/admin',
    menu: true,
  },
  {
    path: '/login',
    name: 'login',
    icon: 'fas fa-shopping-basket text-yellow',
    component: Login,
    layout: '/auth',
    menu: true,
  },
  {
    path: '/producto/:id',
    name: 'Producto',
    icon: 'fas fa-shopping-basket text-yellow',
    component: Producto,
    layout: '/admin',
    menu: false,
  },
  {
    path: '/cupones',
    name: 'Cupones',
    icon: 'fas fa-tags text-yellow',
    component: Cupones,
    layout: '/admin',
    menu: true,
  },
  {
    path: '/cupon/:id',
    name: 'Cupon',
    icon: 'fas fa-tag text-yellow',
    component: CuponEdit,
    layout: '/admin',
    menu: false,
  },
  {
    path: '/cupon',
    name: 'Cupon',
    icon: 'fas fa-tag text-yellow',
    component: CuponAdd,
    layout: '/admin',
    menu: false,
  },
]
export default routes
