import { createNotification } from 'components/Notificacion'
import React from 'react'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import { Button } from 'reactstrap'
import { cuponService } from 'services/cuponService'

import PageContainer from '../components/PageContainer'
import CuponForm from './CuponForm'

const CuponAdd = () => {
  const history = useHistory()
  const queryClient = useQueryClient()

  const onSave = async (data) => {
    try {
      const cuponData = { ...data }

      if (cuponData.productos && cuponData.tipo_seleccion === 'productos') {
        cuponData.productos = cuponData.productos.map((item) => {
          return { id: item.value }
        })
      }

      await cuponService.add(cuponData)
      createNotification('success', 'Información del cupón actualizado.')

      queryClient.invalidateQueries('cupones')

      history.goBack()
    } catch (err) {
      createNotification(
        'error',
        'Hubo un error al momento de actualizar el cupón.'
      )
    }
  }

  return (
    <PageContainer
      className="mb-5"
      title={'Agregar cupón nuevo'}
      actions={
        <>
          <Button className="mt-1" onClick={() => history.goBack()}>
            Regresar
          </Button>
        </>
      }
    >
      <CuponForm onSave={onSave} />
    </PageContainer>
  )
}

export default CuponAdd
