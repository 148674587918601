import { setAuthorizationToken } from './network'

export const sessionService = {
  save(access_token, exp) {
    localStorage.setItem('bees_token', access_token)
    localStorage.setItem('exp', exp)
    localStorage.setItem('exp_mili', exp * 1000)

    setAuthorizationToken(access_token)
  },
}
