import React from 'react'
import { Spinner } from 'reactstrap'

const Loader = ({ show }) => {
  return (
    <div className="row">
      <div className="col">
        {show === true && (
          <>
            <p>Espere un momento...</p>
            <Spinner color="dark" />
          </>
        )}
      </div>
    </div>
  )
}

export default Loader
