import { createNotification } from 'components/Notificacion'
import React, { useMemo } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import { Button } from 'reactstrap'
import { cuponService } from 'services/cuponService'

import PageContainer from '../components/PageContainer'
import CuponForm from './CuponForm'

const CuponEdit = () => {
  const history = useHistory()
  const { id } = useParams()
  const queryClient = useQueryClient()

  const getCupon = async () => {
    const { data } = await cuponService.get(id)

    return data
  }

  const {
    data: cuponData,
    error,
    isLoading,
    isRefetching,
    refetch,
  } = useQuery(`cupon_` + id, getCupon, {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  })

  const onSave = async (data) => {
    try {
      const cuponData = { ...data }

      if (cuponData.productos && cuponData.tipo_seleccion === 'productos') {
        cuponData.productos = cuponData.productos.map((item) => {
          return { id: item.value }
        })
      }

      await cuponService.update(id, cuponData)
      createNotification('success', 'Información del cupón actualizado.')

      queryClient.invalidateQueries('cupones')
    } catch (err) {
      createNotification(
        'error',
        'Hubo un error al momento de actualizar el cupón.'
      )
    }
  }

  const defaultValues = useMemo(() => {
    if (cuponData) {
      const { productos, ...data } = cuponData

      const selectedProductos = productos.map((item) => {
        return {
          label: item.producto.descripcion,
          value: item.producto_id,
        }
      })

      return {
        ...data,
        productos: selectedProductos,
      }
    } else {
      return {}
    }
  }, [cuponData])

  return (
    <PageContainer
      className="mb-5"
      title={`Editar cupón: ${cuponData && cuponData.nombre}`}
      error={error}
      isLoading={isLoading || isRefetching}
      refetch={refetch}
      actions={
        <>
          <Button className="mt-1" onClick={() => history.goBack()}>
            Regresar
          </Button>
        </>
      }
    >
      <CuponForm id={id} defaultValues={defaultValues} onSave={onSave} />
    </PageContainer>
  )
}

export default CuponEdit
