import React from 'react'
import { Card, CardBody } from 'reactstrap'

const LoadingMessage = ({ message = 'Cargando datos' }) => {
  return (
    <Card>
      <CardBody>
        <h3>
          <i className="fas fa-spinner fa-pulse"></i> {message}
        </h3>
      </CardBody>
    </Card>
  )
}

export default LoadingMessage
