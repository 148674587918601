import { Store } from 'layouts/Context'
import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { productoService } from 'services/productoService'

import { getDatafromExcel } from './ExcelHook'

const ProductosHook = () => {
  const { createNotification } = useContext(Store)
  const [productosExcel, setProductosExcel] = useState([])
  const [visible, setVisible] = useState(false)
  const [errorExcel, setErrorExcel] = useState(false)
  const [disableCargar, setDisableCargar] = useState(true)

  const subirProductos = async () => {
    setVisible(true)
    try {
      const { data } = await productoService.upsert({
        data: productosExcel,
      })
      setVisible(false)
      return data
    } catch (error) {
      setVisible(false)
      createNotification('error', 'Error al momento de subir los productos')
    }
  }

  const leerExcel = async (e) => {
    const files = e.target.files,
      f = files[0]
    const reader = new FileReader()
    reader.onload = async function (e) {
      const data = new Uint8Array(e.target.result)
      try {
        const excelData = await getDatafromExcel(data)
        excelData.pop() // remove empty row

        const info = excelData.map((row) => {
          return {
            ...row,
            destacado: row.destacado || 0,
            precio_descuento: row.precio_descuento || 0,
          }
        })

        if (info.length === 0) {
          setDisableCargar(true)
          setErrorExcel(true)
          return
        }

        const requestColumns = [
          'clave',
          'descripcion',
          'caracteristicas',
          // "tipo",
          'componentes',
          // "marca",
          'existencia',
          'precio1',
          'precio2',
          'precio3',
          'precio_descuento',
          'departamento',
          'categoria',
          'destacado',
          'ieps',
          'impuesto',
          'peso',
        ]

        const excelColumns = Object.keys(info[0])
        let excelValid = true

        if (excelColumns.length !== requestColumns.length) {
          excelValid = false
        }

        if (excelValid) {
          for (const column of excelColumns) {
            if (!requestColumns.includes(column)) {
              excelValid = false
              break
            }
          }
        }

        if (!excelValid) {
          setDisableCargar(true)
          setErrorExcel(true)
        } else {
          setProductosExcel(info)

          setDisableCargar(false)
          setErrorExcel(false)
        }
      } catch (error) {
        console.log(error)
      }
    }
    reader.readAsArrayBuffer(f)
  }

  const getProductos = async () => {
    try {
      const { data } = await productoService.getAll()
      const productos = data.map((item) => {
        item.imagen = item.url === null ? 'No' : 'Si'
        item.editar = (
          <Link
            className="btn btn-warning btn-sm"
            to={{
              pathname: `producto/${item.id}`,
              state: { producto: { ...item } },
            }}
          >
            Editar
          </Link>
        )
        return item
      })

      return productos
      // return productos.slice(0, 50);
    } catch (error) {
      createNotification('error', 'Error al momento de consultar los productos')

      return []
    }
  }

  const resetCargaExcel = () => {
    setDisableCargar(true)
    setErrorExcel(false)
  }

  return {
    subirProductos,
    leerExcel,
    visible,
    getProductos,
    errorExcel,
    disableCargar,
    resetCargaExcel,
  }
}

export default ProductosHook
