import { HTTP } from './network'

export const cuponService = {
  getAll() {
    return HTTP.get('/cupon')
  },
  get(id) {
    return HTTP.get(`/cupon/${id}`)
  },
  add(data) {
    return HTTP.post('/cupon', data)
  },
  update(id, data) {
    return HTTP.put(`/cupon/${id}`, data)
  },
  delete(id) {
    return HTTP.delete(`/cupon/${id}`)
  },
  verify(id, codigo) {
    return HTTP.post(`/cupon/verify`, {
      id,
      codigo,
    })
  },
}
