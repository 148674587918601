/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Header from 'components/Headers/Header'
// core components
import AdminNavbar from 'components/Navbars/AdminNavbar.js'
import Sidebar from 'components/Sidebar/Sidebar.js'
import { logo } from 'constants/images'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
// reactstrap components
import { Container } from 'reactstrap'
import routes from 'routes.js'

import Context from './Context'

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={() => (
        <>
          <Header />
          <Container className="mt--7" fluid>
            <Component />
          </Container>
        </>
      )}
    />
  )
}
const Admin = ({ ...props }) => {
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === '/admin') {
        return (
          <PrivateRoute
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        )
      } else {
        return null
      }
    })
  }
  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name
      }
    }
    return 'Brand'
  }
  return (
    <Context>
      <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: '/admin/logistica',
          imgSrc: logo,
          imgAlt: '...',
        }}
      />
      <div className="main-content" useref="mainContent">
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
        />
        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to="/admin/logistica" />
        </Switch>
      </div>
    </Context>
  )
}

export default Admin
